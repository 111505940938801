import React from "react";
import "./index.css";
import ReactDOM from "react-dom/client";
import LoginPage from "./LoginPage";
import LoginDialog from "./LoginDialog";
import { query, isMobile } from "./utils";
const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(
  `
      __ _____ _____ _____ _____ _____ _____ _____ _____ 
   __|  |     |  _  |   | |   __|  |  |     |  |  |   __|
  |  |  |-   -|     | | | |  |  |  |  |  |  |    -|   __|
  |_____|_____|__|__|_|___|_____|_____|_____|__|__|_____|
  `
);

root.render(
  <React.StrictMode>
    {query.dialog ? <LoginDialog /> : <LoginPage />}
  </React.StrictMode>
);
